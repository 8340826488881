import styled from "react-emotion";

const ClientsDescription = styled.p`
    font-weight: normal;
    line-height: 1.45;
    font-size: 1em;
    letter-spacing: -0.02em;
    color: #D1EAFF;
    margin: 0;
    margin-top: 0.75em;
`;

export default ClientsDescription;
