import styled from "react-emotion";

const ColumnWrap = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

export default ColumnWrap;
