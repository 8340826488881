import styled from "react-emotion";

const ClientsItem = styled.div`
    display: flex;
    margin-top: 3em;
    box-sizing: border-box;
    width: 100%;

    @media screen and (min-width: 768px){
        width: 33.3333%;
        padding: 0 1em;

        &:nth-child(2) {
            width: 66.666%;
        }
    }
`;

export default ClientsItem;
