import React from "react";
import PropTypes from "prop-types";

import ColumnWrap from "./ColumnWrap";
import ClientsItem from "./ClientsItem";
import ClientsLink from "./ClientsLink";
import ClientsDescription from "./ClientsDescription";
import ClientsNumber from "./ClientsNumber";

const ServicesList = ( {clientsData} ) => {
    return (
        <ColumnWrap>
                {clientsData.map(item =>
                    <ClientsItem key={item.id} >
                        <ClientsNumber>0{item.id}</ClientsNumber>
                        <div>
                            <ClientsLink href={item.linkHref} target="_blank" rel="nofollow norefferer noopener" title="Перейти на сайт">{item.linkText}</ClientsLink>
                            <ClientsDescription>{item.description}</ClientsDescription>
                        </div>
                    </ClientsItem>
                )}
        </ColumnWrap>
    );
};

export default ServicesList;

ServicesList.propTypes = {
    clientsData: PropTypes.array.isRequired
};
