import styled from "react-emotion";

const DomainsTitle = styled.h2`
    font-size: 1.29em;
    line-height: 1.556;
    font-weight: 700;
    letter-spacing: -0.013em;
    margin: 0 auto;
    color: #fff;
    text-align: center;

    @media screen and (min-width: 768px) {
        font-size: 2em;
        line-height: 1.5;
        margin-bottom: 1em;
        max-width: 75%;
    }
`;

export default DomainsTitle;
