import styled from "react-emotion";

const ClientsLink = styled.a`
    font-weight: 700;
    font-size: 1.125em;
    line-height: 1.25;
    letter-spacing: -0.013em;
    position: relative;
    display: inline-block;
    color: #fff;
    white-space: pre-line;
    text-decoration: none;

    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        background-color: #fff;
        transform: scaleX(0);
        transform-origin: left;
        transition: all .2s ease-in-out;
    }

    &:hover::after {
        transform: scaleX(1);
    }
`;

export default ClientsLink;
