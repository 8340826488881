import React from "react";
import styled from "react-emotion";

import Container from "./Container";
import DomainsTitle from "./DomainsTitle";
import ServicesList from "./ServicesList";

const ClientsBlock = () => {

    const ClientsData = [
        {
            "id": "1",
            "linkText": "cctld.ru",
            "linkHref": "//cctld.ru/",
            "description": "Координационный центр доменов .RU/.РФ"
        },
        {
            "id": "2",
            "linkText": "cktbp.ru",
            "linkHref": "//cktbp.ru/",
            "description": "Акционерное общество “Центральное конструкторско-технологическое бюро полимерных материалов с опытным производством”"
        },
        {
            "id": "3",
            "linkText": "sharapova.ru",
            "linkHref": "//sharapova.ru/",
            "description": "Сайт Марии Шараповой"
        },
        {
            "id": "4",
            "linkText": "sibcapomsk.ru",
            "linkHref": "//sibcapomsk.ru/",
            "description": "ПАО Сибирский капитал"
        },
        {
            "id": "5",
            "linkText": "ddt.ru",
            "linkHref": "//ddt.ru",
            "description": "Сайт группы ДДТ"
        }
    ];

    return (
        <Services>
            <Container>
                <DomainsTitle>Наши клиенты</DomainsTitle>
                <ServicesList clientsData = { ClientsData } />
            </Container>
        </Services>
    );
};

export default ClientsBlock;

const Services = styled.section`
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1600 - 320)));
    background: #02508B;
    width: 100%;
    position: relative;
    padding: 3.4em 0;

    @media screen and (min-width: 768px) {
        padding: 4em 0;
    }
`;
