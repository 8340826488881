import styled from "react-emotion";

const ClientsNumber = styled.div`
    color: #01BAEF;
    font-weight: bold;
    font-size: 1.125em;
    line-height: 1.25;
    letter-spacing: -0.013em;
    margin-right: 0.888em;
`;

export default ClientsNumber;
